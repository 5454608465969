<template>
    <v-container fluid>
        <PageHeaderSlot>
            <template slot="top-right-slot">
                <v-btn text depressed class="primary ml-2" :to="{ name: 'CreateProductCategory' }">
                    <v-icon size="20" class="mr-3">{{ icon.mdiPlusCircleOutline }}</v-icon>
                    新增分類
                </v-btn>
            </template>
        </PageHeaderSlot>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            @options-update="onTableOptionsChange"
            actionViewRouteLink="ProductCategoryDetails"
            @delete-clicked="handleDelete($event)"
        ></Datatable>
        <ConfirmDialog ref="confirmDeleteDialog" title="是否刪除此商品分類？" deleteDescription="是否確定要刪除此商品分類？此動作無法復原。"></ConfirmDialog>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiPlusCircleOutline } from '@mdi/js';
import Datatable from '@/components/Datatable.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
    name: 'ProductCategoryList',
    components: {
        Datatable,
        ConfirmDialog
    },
    data: () => ({
        icon: {
            mdiPlusCircleOutline
        },
        tableHeaders: [
            { value: 'category_name', text: '分類名稱' },
            { value: 'actionView', text: '', align: 'end', width: 90 },
            { value: 'actionDelete', text: '', align: 'end', width: 90 },
        ],

        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
    }),
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog']),
        async getProductCategoryData() {
            this.tableLoading = true;
            try {
                let payload = {
                    filter_limit: this.tableLimit,
                    filter_page: this.tablePage - 1,
                };

                const { data, total } = await this.$Fetcher.GetProductCategories(payload);
                this.tableData = data.map(el => {
                    return {
                        id: el.id,
                        category_name: el.name,
                    }
                })
                
                this.tableItemTotal = total;
                this.tableLoading = false;
            } catch(err) {
                this.tableData = [];
                this.tableLoading = false;
            }
        },
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getProductCategoryData();
        },

        async handleDelete(id) {
            const confirm = await this.$refs.confirmDeleteDialog.show();
            if (confirm) {
                this.$store.dispatch('setLoading', true);
              
                try {
                    await this.$Fetcher.DeleteProductCategory(id);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '刪除成功',
                        type: 'success',
                        refresh: true,
                        redirect: ''
                    });
                } catch (err) {
                    this.setDialogMessage({
                        title: "刪除失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }

        },
    },
    async created() {
        await this.getProductCategoryData();
    }
}
</script>